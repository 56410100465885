export const NewOpportunityDefault = {
	id: null,
	form_template: null,
	department: null,
	sponsor: null,
	contact: null,
	term: null,
	hours_per_week: null,
	// funding: null,
	themes: null,
	learning_outcomes: null,
	keywords: null,
	student_classifications: null,
	student_majors: null,
	student_years: null,
	work_authorization_required: false,
	financial_aid_available: false,
	deadline_date: null,
	start_date: null,
	end_date: null,
	opportunity_start_date: null,
	opportunity_end_date: null,
	status: null,
	texts: {
		title: null,
		tagline: null,
		external_application_url: null,
		overview: null,
		website_url: null,
		safety_considerations: null,
		costs: null,
		prerequisites: null
	},
	location: null,
	payment: [
		{ name: 'volunteer', required: true, selected: false, showInput: false, title: 'None / Volunteer', value: 0 },
		{
			title: 'Pay: Hourly Wage',
			selected: false,
			value: undefined,
			name: 'hourly_wage',
			required: true

			// textboxProperties: { numericOnly: true }
		},
		{
			title: 'Pay: Stipend',
			selected: true,
			value: undefined,
			name: 'stipend',
			required: true,
			helper_text: '(Not an option for UROPs)'
			// textboxProperties: { numericOnly: true }
		},
		{
			title: 'Course Credit',
			selected: false,
			value: undefined,
			name: 'credit',
			required: true,
			helper_text: '(e.g., subject number, units arranged, etc. )'
		}
	],
	has_external_application: false,
	use_contact_email_for_application: false,
	has_deadline: false,
	has_website_url: false,
	has_cost: false,
	has_prerequisites: false,
	is_remote: false,
	has_moira_list: false,
	is_always_live: false
}
