import { Button, ButtonType, TemplateModal, TemplateModalAlignment, Text, TextType } from '@mit/hui'
import React, { useState } from 'react'
import { OpportunityController } from 'Api/controller'
import { Opportunity } from 'Types'
import { BannerMessage } from '../BannerMessage'

interface Props {
	data: Opportunity
	onSuccess: () => void
}

export const OpportunityArchive: React.FC<Props> = ({ data, onSuccess }) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [banner, setBanner] = useState<any>({ type: '', text: `` })

	const handleClick = () => {
		setShowConfirmationModal(true)
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const controller = new OpportunityController()
		controller
			.archiveOpportunity(data.id)
			.then((data) => {
				setIsLoading(false)
				onSuccess()
			})
			.catch((error) => {
				setBanner({ type: 'error', text: error })
				setIsLoading(false)
			})
	}

	return (
		<>
			<Button text='Archive Opportunity' onClick={handleClick} type={ButtonType.Secondary} />
			<TemplateModal
				show={showConfirmationModal}
				onClose={() => setShowConfirmationModal(false)}
				header={<Text content='Archive Opportunity' type={TextType.Heading4} />}
				body={
					<div className='d-flex flex-column'>
						<BannerMessage banner={banner} />
						<Text content='Are you sure you want to archive this opportunity?' />
					</div>
				}
				footer={<Button text='Archive Opportunity' onClick={handleSubmit} isBusy={isLoading} />}
				bodyAlignment={TemplateModalAlignment.Center}
				name='archive_modal'
			/>
		</>
	)
}
