import React, { useEffect, useRef, useState } from 'react'
import { Text, TextType, GridContainer, LayoutColumn, Button } from '@mit/hui'
import { FormField } from 'Components/form/FormField'
import { useForm } from 'react-hook-form'
import { Lookups, Opportunity, OpportunityFormType } from 'Types'
import { OpportunityController } from 'Api/controller'
import { getTypeText } from 'Common/Opportunity'
import { Banner } from 'Types/banner'
import { BannerMessage } from '../BannerMessage'
import { TemplatePreview } from '../../opportunity/TemplatePreview'
import { getDateString } from 'Common'
import { OpportunityRejectionForm } from './OpportunityRejectionForm'
import { UropAlert } from './UropAlert'
import { OpportunityNote } from '../../opportunity'

interface Props {
	type: OpportunityFormType
	data: Opportunity
	lookups: Lookups
	refreshOpportunities: any
	approver_themes: { id?: number; dlc_keys?: string[] }[]
	banner: Banner
	setBanner: (banner: Banner) => void
	onUpdate: (id: string) => void
}

const controller = new OpportunityController()

export const OpportunityForm: React.FC<Props> = ({ type, data, lookups, refreshOpportunities, approver_themes, banner, setBanner, onUpdate }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [showRequestChangeModal, setShowRequestChangeModal] = useState<boolean>(false)
	const refFrom = useRef<any>()
	const topRef = useRef<HTMLDivElement>(null)

	const methods: ReturnType<typeof useForm> = useForm<any>({
		defaultValues: data,
		reValidateMode: 'onSubmit'
	})
	const { handleSubmit, watch, reset } = methods
	let formData = watch()

	const can_approve =
		approver_themes.filter(
			(theme) =>
				theme.id === data.primary_theme?.id &&
				theme.dlc_keys !== undefined &&
				theme.dlc_keys?.filter((key) => key === data.department?.id).length > 0
		).length > 0
	// const handleSubmitWrapper = (data: any) => {
	// 	onSubmit(data)
	// }

	const onSubmit = (formData: any, auto_approve = false) => {
		setIsLoading(true)

		if (formData.texts) {
			formData.texts.external_application_url = formData.texts?.external_application_url?.replace('https://', '')?.replace('http://', '')
			formData.texts.website_url = formData.texts?.website_url?.replace('https://', '')?.replace('http://', '')
		}

		if (type === 'CREATE') {
			controller
				.createOpportuniy(formData, auto_approve)
				.then((resp: any) => {
					setIsLoading(false)
					onUpdate(resp.id)
					refreshOpportunities()
					setBanner({
						type: 'success',
						text: `${auto_approve ? 'Opportunity Created & Approved' : 'Opportunity Created & Awaiting Approval'}`
					})
				})
				.catch((error) => {
					setIsLoading(false)
					scrollToTop()
					setBanner({ type: 'error', text: error })
				})
		} else if (type === 'UPDATE') {
			if (data.funding?.id) {
				formData.funding = data.funding
			}
			controller
				.updateOpportuniy(data.id || '', formData, auto_approve)
				.then((resp: any) => {
					setIsLoading(false)
					onUpdate(resp.id)
					refreshOpportunities()
					setBanner({
						type: 'success',
						text: `${auto_approve ? 'Opportunity Updated & Approved' : 'Opportunity Updated & Awaiting Approval'}`
					})
				})
				.catch((error) => {
					setIsLoading(false)
					scrollToTop()
					setBanner({ type: 'error', text: error })
				})
		} else if (type === 'APPROVE') {
			controller
				.approveOpportuniy(data.id || '')
				.then((resp: any) => {
					setIsLoading(false)
					onUpdate(resp.id)
					refreshOpportunities()
					setBanner({ type: 'success', text: `Opportunity Approved` })
				})
				.catch((error) => {
					setIsLoading(false)
					scrollToTop()
					setBanner({ type: 'error', text: error })
				})
		}
	}

	const scrollToTop = () => {
		if (topRef && topRef.current) topRef.current.scrollIntoView()
	}

	const onPressSubmitButton = async (auto_approve?: boolean) => {
		const isValid = await methods.trigger()
		setBanner({ type: 'info', text: '' })
		if (isValid) onSubmit(formData, auto_approve)
		else {
			// console.log(methods.errors)
			setBanner({ type: 'error', text: 'Please fill in all the required fields' })
			scrollToTop()
		}
	}

	const onPressRejectButton = () => {
		setShowRequestChangeModal(true)
	}

	// const getfunding_sources = () => {
	// 	let showEloFunded = false
	// 	if (formData.payment && formData.payment.length) {
	// 		showEloFunded = formData.payment.filter((row: any) => row.selected && row.title.indexOf('Pay') !== -1).length > 0
	// 	}

	// 	if (showEloFunded) {
	// 		return lookups.funding_sources
	// 	}

	// 	if (formData.funding && formData.funding.id === 1) {
	// 		methods.setValue('funding', null)
	// 	}

	// 	return lookups.funding_sources?.filter((row: any) => row.id !== 1)
	// }

	const getStudentEligibility = () => {
		let items = lookups.student_classifications
		// only show undergraduate for UROP
		if (formData.primary_theme && formData.primary_theme.id === 1) {
			items = items?.filter((row) => row.id === 1)

			if (formData.student_classifications && formData.student_classifications.length > 0) {
				let hasInvalidEntry = formData.student_classifications.find((row: any) => row.id !== 1)

				if (hasInvalidEntry) {
					let filteredData = formData.student_classifications.filter((row: any) => row.id === 1)
					debugger
					methods.setValue('student_classifications', filteredData)
				}
			}
		}
		return items
	}

	const getThemes = () => {
		// if (formData.primary_theme && formData.themes && formData.themes.length) {
		// 	let themes = formData.themes.filter((item: any) => item.id !== formData.primary_theme?.id)
		// 	if (themes.length !== formData.themes.length) methods.setValue('themes', themes)
		// }

		return lookups.themes?.filter((item: any) => item.id !== formData.primary_theme?.id)
	}

	const getPaymentOptions = () => {
		const items = formData.primary_theme?.id !== 1 ? data?.payment : data?.payment.filter((row: any) => row.name !== 'stipend')
		const selectedItems = items.filter((row: any) => row.selected)
		if (selectedItems.length === 0 && items[1]) {
			items[1].selected = true
		}

		console.log('payment', items)
		return items
	}

	const tempOnSubmit = (data: any) => { }

	//console.log(errors)

	useEffect(() => {
		reset(data)
	}, [data, reset])

	//console.log(data)

	//console.log('type', type)
	console.log(formData)

	return (
		<>
			<div ref={topRef} />
			{!!data.id && (
				<div className='d-flex py-2 flex-wrap'>
					<span className='mr-3'>
						Created by{' '}
						<b>
							{data.created_by} - {getDateString(data.created_on || '')}
						</b>
					</span>
					{!!data.updated_by && (
						<span className='mr-3'>
							Last updated by{' '}
							<b>
								{data.updated_by} - {getDateString(data.updated_on || '')}
							</b>
						</span>
					)}
					{!!data.approved_by && (
						<span>
							Approved by{' '}
							<b>
								{data.approved_by} - {getDateString(data.approved_on || '')}
							</b>
						</span>
					)}
				</div>
			)}
			{!!data.notes && !!data.notes.length && (
				<div>
					<Text type={TextType.Heading5} content={'Requested Change Notes'} />
					<OpportunityNote notes={data.notes as any} />
				</div>
			)}
			{/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
			{/* <br /> */}
			<form id='opportunity-form' onSubmit={handleSubmit(tempOnSubmit)} ref={refFrom}>
				<BannerMessage banner={banner} />

				<div className='border-bottom pb-3'>
					<Text type={TextType.Heading1} content='Basic Information' padded />

					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6} alignment='left'>
							<div className='form-template-container mb-4'>
								<FormField
									readonly={type === 'APPROVE'}
									name='department'
									editor='autocomplete'
									methods={methods}
									label='Department/Program'
									properties={{
										items: lookups.departments || [],
										placeholder: 'Select a department/program',
										showItemsOnFocus: true,
										limit: 200
									}}
									required
								/>
								<p className='helper-text'>
									If you have your own application system or do not wish to use an application process, please select external
									application. Be sure to specify the external application URL (if applicable) or choose the "students should
									connect..." option.
								</p>

								<FormField
									readonly={type === 'APPROVE'}
									name='has_external_application'
									editor='toggle'
									methods={methods}
									label='External Application'
									properties={{}}
								/>
								{formData.has_external_application ? (
									<>
										<FormField
											readonly={type === 'APPROVE'}
											name='use_contact_email_for_application'
											editor='toggle'
											methods={methods}
											label='Students Should Connect Directly With Specified Contact Email'
											properties={{}}
										/>

										{!formData.use_contact_email_for_application && (
											<FormField
												readonly={type === 'APPROVE'}
												name='texts.external_application_url'
												editor='textbox'
												methods={methods}
												label='Link to External Application'
												properties={{ placeholder: 'Please enter the link' }}
												required
											/>
										)}
									</>
								) : (
									<>
										<FormField
											readonly={type === 'APPROVE'}
											name='form_template'
											editor='dropdown'
											methods={methods}
											label='Template'
											properties={{ items: lookups.form_templates || [], placeholder: 'Select a template' }}
											padded={false}
											required
										/>
										{!!formData.form_template && <TemplatePreview data={formData.form_template} />}
									</>
								)}
							</div>

							<FormField
								readonly={type === 'APPROVE'}
								name='texts.title'
								editor='textbox'
								methods={methods}
								label='ELO Project Title'
								properties={{ placeholder: 'Please enter a title' }}
								value={formData.texts.title}
								required
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='texts.tagline'
								editor='textbox'
								methods={methods}
								label='Tagline / Subtitle'
								properties={{ placeholder: 'Please enter a catchy phrase' }}
							/>

							{/* <br /> */}
							{/* <FormField
								readonly={type === 'APPROVE'}
								name="has_external_application"
								editor="toggle"
								methods={methods}
								label="External Application"
								properties={{}}
							/>
							{!!formData.has_external_application && (
								<FormField
									readonly={type === 'APPROVE'}
									name="texts.external_application_url"
									editor="textbox"
									methods={methods}
									label="Link to External Application"
									properties={{ placeholder: 'Please enter the link' }}
									required
								/>
							)} */}
							{/* <br /> */}
							<FormField
								readonly={type === 'APPROVE'}
								name='primary_theme'
								editor='dropdown'
								methods={methods}
								label='Primary Theme / Track / Program'
								properties={{
									items: lookups.themes || [],
									placeholder: 'Select a theme/tracks/program that applies',
									// type: 'tags',
									showItemsOnFocus: true
								}}
								required
							/>
							{formData.primary_theme?.id === 1 && <UropAlert urop_deadlines={lookups.urop_deadlines} />}
							<FormField
								readonly={type === 'APPROVE'}
								name='themes'
								editor='autocomplete'
								methods={methods}
								label='Additional Themes / Tracks / Programs'
								properties={{
									items: getThemes() || [],
									placeholder: 'Select all theme/tracks/program that apply',
									type: 'tags',
									showItemsOnFocus: true
								}}
							/>
							{/* <FormField 	readonly={type === 'APPROVE'  }
								name="learning_outcomes"
								editor="autocomplete"
								methods={methods}
								label="Learning Outcomes"
								properties={{
									items: lookups.learning_outcomes || [],
									placeholder: 'Select all that apply',
									type: 'tags',
									showItemsOnFocus: true
								}}
							/> */}
							<FormField
								readonly={type === 'APPROVE'}
								name='keywords'
								editor='autocomplete'
								methods={methods}
								label='Focus Area / Keyword'
								properties={{
									items: lookups.keywords || [],
									placeholder: 'Type keyword and select existing or add your own',
									type: 'creatable-tags',
									showItemsOnFocus: true,
									creatablehelper_text: 'Create a new keyword',
									clearOnBlur: false
								}}
								required
							/>
						</LayoutColumn>
						<LayoutColumn colSize={6} alignment='left'>
							<FormField
								readonly={type === 'APPROVE'}
								name='is_remote'
								editor='toggle'
								methods={methods}
								label='Remote / Virtual'
								properties={{}}
							/>
							{!!!formData.is_remote && (
								<FormField
									readonly={type === 'APPROVE'}
									name='location'
									editor='location-search'
									methods={methods}
									label='Location'
									properties={{ placeholder: 'Search for a location' }}
									required
								/>
							)}
							<FormField
								readonly={type === 'APPROVE'}
								name='terms'
								editor='autocomplete'
								methods={methods}
								label='Term'
								properties={{
									items: lookups.terms || [],
									placeholder: 'Select all terms that apply',
									type: 'tags',
									showItemsOnFocus: true
								}}
								required
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='hours_per_week'
								editor='dropdown'
								methods={methods}
								label='Estimated Hours Per Week'
								properties={{ items: lookups.hours || [], placeholder: 'Select hours' }}
								required
							/>
							<br />
							{/* <Text content="Enrollment Dates" type={TextType.Heading5} /> */}
							{/* <FormField
								readonly={type === 'APPROVE'}
								name="is_always_live"
								editor="toggle"
								methods={methods}
								label='Permanent Posting'
								properties={{}}
							/> */}
							{/* {!!!formData.is_always_live && ( */}
							<>
								<div >
									<h6 className='mb-2'>Listing Viewable Dates</h6>
									<p className='mb-3 helper-text'>
										Specify the dates you want this listing viewable to students online.
									</p>

									{/* &nbsp; (Visible on Student Portal) */}
								</div>
								<div className='d-flex justify-content-between date-start-end'>
									<FormField
										readonly={type === 'APPROVE'}
										name='start_date'
										editor='calendar'
										methods={methods}
										label='Start Date'
										properties={{ placeholder: 'Select a Date' }}
										required
									/>
									<FormField
										readonly={type === 'APPROVE'}
										name='end_date'
										editor='calendar'
										methods={methods}
										label='End Date'
										properties={{ placeholder: 'Select a Date' }}
										required
									/>
								</div>
							</>
							{/* )} */}
							<FormField
								readonly={type === 'APPROVE'}
								name='has_deadline'
								editor='toggle'
								methods={methods}
								label='ELx Opportunity Application Deadline'
								properties={{}}
							/>
							{formData.has_deadline && (
								<FormField
									readonly={type === 'APPROVE'}
									name='deadline_date'
									editor='calendar'
									methods={methods}
									label='Deadline Date'
									properties={{ placeholder: 'Select a Date' }}
									required
								/>
							)}
							{/*<h6 className='mb-2'>Student Participation Dates</h6>
							<div className='d-flex justify-content-between date-start-end'>
								<FormField
									readonly={type === 'APPROVE'}
									name='opportunity_start_date'
									editor='calendar'
									methods={methods}
									label='Start Date'
									properties={{ placeholder: 'Select a Date' }}
								/>
								<FormField
									readonly={type === 'APPROVE'}
									name='opportunity_end_date'
									editor='calendar'
									methods={methods}
									label='End Date'
									properties={{ placeholder: 'Select a Date' }}
								/>
							</div>*/}
						</LayoutColumn>
					</GridContainer>
				</div>
				{/* OVERVIEW */}
				<div className='border-bottom pb-3'>
					<Text type={TextType.Heading1} content='Overview' padded />

					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6} alignment='left'>
							<FormField
								readonly={type === 'APPROVE'}
								name='texts.overview'
								editor='textarea'
								methods={methods}
								label='Overview/Description'
								properties={{ placeholder: 'Please enter a brief description of the opportunity' }}
								required
							/>

							<FormField
								readonly={type === 'APPROVE'}
								name='has_website_url'
								editor='toggle'
								methods={methods}
								label='Website'
								properties={{ placeholder: '' }}
							/>

							{formData.has_website_url && (
								<FormField
									readonly={type === 'APPROVE'}
									name='texts.website_url'
									editor='textbox'
									methods={methods}
									label='Link to Website'
									properties={{ placeholder: 'Please enter a link to more information' }}
								/>
							)}
						</LayoutColumn>
					</GridContainer>
				</div>
				{/* Requirements */}
				<div className='border-bottom pb-3'>
					<Text type={TextType.Heading1} content='Requirements' padded />
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6} alignment='left'>
							<FormField
								readonly={type === 'APPROVE'}
								name='work_authorization_required'
								editor='toggle'
								methods={methods}
								label='US Work Authorization Required'
								properties={{ placeholder: '' }}
							/>

							<FormField
								readonly={type === 'APPROVE'}
								name='texts.safety_considerations'
								editor='textarea'
								methods={methods}
								label='Safety Considerations'
								properties={{ placeholder: 'Please enter any saftey considerations' }}
							/>
						</LayoutColumn>
					</GridContainer>
				</div>
				{/* COSTS */}
				<div className='border-bottom pb-3'>
					<Text type={TextType.Heading1} content='Student Compensation and Expenses' padded />
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6} alignment='left'>
							<FormField
								readonly={type === 'APPROVE'}
								name='payment'
								editor='checkboxinputgroup'
								methods={methods}
								label='Compensation'
								properties={
									{
										placeholder: 'Please specify and amount',
										group: 'payment',
										groupOptions: getPaymentOptions(),
										required: true
									} || {}
								}
								required
							/>
							{/* <FormField
								readonly={type === 'APPROVE'}
								name='funding'
								editor='dropdown'
								methods={methods}
								label='Funding Source'
								properties={{
									items: getfunding_sources() || [],
									placeholder: 'Please select a funding source'
								}}
								required
							/> */}
							<FormField
								readonly={type === 'APPROVE'}
								name='has_cost'
								editor='toggle'
								methods={methods}
								label='Expenses Paid by Student'
								properties={{ placeholder: '' }}
							/>
							{formData.has_cost && (
								<>
									<FormField
										readonly={type === 'APPROVE'}
										name='texts.costs'
										editor='textarea'
										methods={methods}
										label='Costs'
										properties={{ placeholder: 'Please enter estimated cost of participation' }}
									/>
									<FormField
										readonly={type === 'APPROVE'}
										name='financial_aid_available'
										editor='toggle'
										methods={methods}
										label='Financial aid available'
										properties={{ placeholder: '' }}
									/>
								</>
							)}
						</LayoutColumn>
					</GridContainer>
				</div>
				{/* Academics */}
				<div className='border-bottom pb-3'>
					<h1 className='mt-3 mb-0'>Academics</h1>
					<p className='mb-3 helper-text'>
						Specify any student eligibility requirements or prerequisites below. Leaving the eligibility field blank is equivalent to
						selecting all options; all students (regardless of degree/major/year) will see your posting by default.
					</p>

					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6} alignment='left'>
							<FormField
								readonly={type === 'APPROVE'}
								name='student_classifications'
								editor='autocomplete'
								methods={methods}
								label='Student Eligibility'
								properties={{
									items: getStudentEligibility() || [],
									placeholder: 'Select all that apply',
									type: 'tags',
									showItemsOnFocus: true
								}}
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='student_years'
								editor='autocomplete'
								methods={methods}
								label='Class Year'
								properties={{
									items: lookups.student_years || [],
									placeholder: 'Select all that apply',
									type: 'tags',
									showItemsOnFocus: true
								}}
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='student_majors'
								editor='autocomplete'
								methods={methods}
								label='Major'
								properties={{
									items: lookups.student_majors || [],
									placeholder: 'Select all that apply',
									type: 'tags',
									showItemsOnFocus: true,
									limit: 500
								}}
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='has_prerequisites'
								editor='toggle'
								methods={methods}
								label='Required Prerequisites'
								properties={{ placeholder: '' }}
							/>
							{formData.has_prerequisites && (
								<FormField
									readonly={type === 'APPROVE'}
									name='texts.prerequisites'
									editor='textarea'
									methods={methods}
									label='Prerequisites'
									properties={{ placeholder: 'List academic courses, technical skills and/or required training' }}
								/>
							)}
						</LayoutColumn>
					</GridContainer>
				</div>
				{/* Contact */}
				<div className='pb-3'>
					<Text type={TextType.Heading1} content='Contact' padded />
					<GridContainer showGutters={true}>
						<LayoutColumn colSize={6} alignment='left'>
							<FormField
								readonly={type === 'APPROVE'}
								name='sponsor'
								editor='person-search'
								methods={methods}
								label='PI / Faculty Sponsor / Program Director'
								properties={{ placeholder: 'Search for a person' }}
								required
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='contact'
								editor='person-search'
								methods={methods}
								label='Contact Person'
								subLabel='(also able to view applicant submissions)'
								properties={{ placeholder: 'Search for a person' }}
								required
							/>
							<FormField
								readonly={type === 'APPROVE'}
								name='contact_email'
								editor='textbox'
								methods={methods}
								label='Alternative Contact Email'
								properties={{ placeholder: 'Enter one valid email (individual or email list)' }}
							// required
							/>
							{/* <FormField
								readonly={type === 'APPROVE'}
								name="has_moira_list"
								editor="toggle"
								methods={methods}
								label="Contact Moira List"
								properties={{}}
							/>
							{!formData.has_moira_list ? (
								<FormField
									readonly={type === 'APPROVE'}
									name="contact"
									editor="person-search"
									methods={methods}
									label="Contact Person"
									properties={{ placeholder: 'Search for a person' }}
									required
								/>
							) : (
								<FormField
									readonly={type === 'APPROVE'}
									name="moira_list"
									editor="textbox"
									methods={methods}
									label="Mailing List"
									properties={{ placeholder: 'Please specify Miora list' }}
									required
								/>
							)} */}
						</LayoutColumn>
					</GridContainer>
				</div>
				{/* <button type="submit" form="opportunity-form" className="btn btn-primary">
					Request Approval
				</button>
				<Button text="Request Approval" onClick={() => methods.handleSubmit(onSubmit)} />
				&nbsp; &nbsp;
				<button type="submit" form="opportunity-form" className="btn btn-primary">
					Approve
				</button>
				<br />
				<br />
				<br />
				<pre>{JSON.stringify(errors, null, 2)}</pre> */}
			</form>
			<Button
				// icon="check"
				text={`${getTypeText(type)}`}
				onClick={async () => {
					onPressSubmitButton()
				}}
				isBusy={isLoading}
			/>
			&nbsp; &nbsp;
			{can_approve && type !== 'APPROVE' && (
				<Button
					// icon="check"
					text={`${getTypeText(type, true)}`}
					onClick={async () => {
						// console.log(methods.getValues())
						onPressSubmitButton(true)
					}}
					isBusy={isLoading}
				/>
			)}
			&nbsp; &nbsp;
			{can_approve && !!data.id && (
				<Button
					// icon="check"
					text={`Request Change`}
					onClick={async () => {
						// console.log(methods.getValues())
						onPressRejectButton()
					}}
					isBusy={isLoading}
				/>
			)}
			<OpportunityRejectionForm
				id={data.id}
				show={showRequestChangeModal}
				onClose={() => setShowRequestChangeModal(false)}
				onComplete={() => {
					onUpdate(data.id)
					refreshOpportunities()
					setBanner({ type: 'success', text: `Opportunity Change Request Sent` })
				}}
			/>
		</>
	)
}
