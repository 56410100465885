

import { AuthProvider } from '@mit/aws-react'
import appConfig from "../../app-config-global";

class AuthProviderLocal {

  getToken = async (): Promise<any> => {
    const authorizationToken = await new AuthProvider(Object.assign(appConfig)).getToken()
    return authorizationToken;
  }

  logout = async (): Promise<any> => {
    await new AuthProvider(Object.assign(appConfig)).signOut()
  }
}

const authProviderLocal = new AuthProviderLocal()
export default authProviderLocal
