import React from 'react'
import { Button, ButtonType, NavBar, NavBarTypes, NavItemState } from '@mit/hui'
import { Opportunity } from 'Types'
import { useStoreActions } from 'Store'

interface Props {
	selectedStatus: string[]
	items: Opportunity[]
	onClick: (status: string[]) => void
}

export const OpportunityNav: React.FC<Props> = ({ items, selectedStatus, onClick }) => {
	const { setShow } = useStoreActions((action) => action.filterModel)
	

	return (
		<div className='d-flex justify-content-between align-items-center flex-wrap'>
			{!!items.length ? (
				<NavBar
					tabs={[
						{
							icon: '',
							iconOnly: false,
							text: 'All',
							state: selectedStatus.length === 0  ? NavItemState.Active : NavItemState.None,
							badgeNumber: items.length,
							badgeColor: 'grey',
							onClick: () => onClick([])
						},
						{
							icon: '',
							iconOnly: false,
							text: 'Live',
							state: selectedStatus?.includes('L') ? NavItemState.Active : NavItemState.None,
							badgeNumber: items.filter((row: any) => row.status?.id === 'L').length,
							badgeColor: 'grey',
							onClick: () => onClick(['L'])
						},
						{
							icon: '',
							iconOnly: false,
							text: 'Approved',
							state: selectedStatus?.includes('A') ? NavItemState.Active : NavItemState.None,
							badgeNumber: items.filter((row: any) => row.status?.id === 'A').length,
							badgeColor: 'grey',
							onClick: () => onClick(['A'])
						},
						{
							icon: '',
							iconOnly: false,
							text: 'Pending Approval',
							state: selectedStatus?.includes('P') ? NavItemState.Active : NavItemState.None,
							badgeNumber: items.filter((row: any) => row.status?.id === 'P').length,
							badgeColor: 'grey',
							onClick: () => onClick(['P','CS','CR'])
						}
					]}
					type={NavBarTypes.Text}
				/>
			) : (
				<span></span>
			)}
			<Button type={ButtonType.Ghost} icon={'filter'} text={'Filter'} onClick={() => setShow(true)} />
		</div>
	)
}
