import React from 'react'
import { ActionList } from '@mit/hui'

interface Props {
	isLoading: boolean
}

export const Loader: React.FC<Props> = ({ isLoading }) => {
	return <ActionList isLoading={isLoading} items={[]} />
}
