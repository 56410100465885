import React, { FC }from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
// import { Initialization, Logout } from 'TemplateConstants/components'
import { Initialization } from '@mit/aws-react'
import reportWebVitals from './reportWebVitals'
import appConfig from './app-config-global'
import 'Scss/main.scss'
import { useAppInitializer } from 'TemplateConstants/components/initializer'
import { Logout } from 'TemplateConstants/components'

	const WrappedOKTAApp: FC = () => {
	const { initializeApp } = useAppInitializer()

	const AppLazyLoad = React.lazy(
		async () =>
			await import('./Components/app/App').then(module => ({
				default: module.default
			}))
	)

	return (
	< Router >
		<React.StrictMode>
			<StoreProvider store={store}>
				<Switch>
					<Route exact path='/logout' component={Logout} />
					<Route path='/'>
						<Initialization
							App={AppLazyLoad}
							appInitializer={initializeApp}
							appConfigData={appConfig}
							loaderProps={{
								contactEmail: 'eol@mit.edu',
								name: 'Elx Admin',
								isLoading: true,
								type: 'Default'
							}}
						/>
					</Route>
				</Switch>
			</StoreProvider>
		</React.StrictMode>
	</Router >
	)
}

ReactDOM.render(
	<StoreProvider store={store}>
		<WrappedOKTAApp />
	</StoreProvider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

