import cloneDeep from 'clone-deep'
import { FilterObject } from 'Types'

export const DefaultFilterObject = {
	departments: [],
	themes: [],
	terms: [],
	has_applications: false,
	sponsors: { id: null, text: '' }
}

export const getDefaultFilterObject = (): FilterObject => {
	let data: any = cloneDeep(DefaultFilterObject)

	return data as FilterObject
}
