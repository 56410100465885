import { CounterModel, counterModel } from './counter'
import { GlobalModel, globalModel } from './global'
import { FormModel, formModel } from './form'
import { FilterModel, filterModel } from './filter'
import { ITemplateModel, TemplateModel } from 'TemplateConstants/store/model'

export interface Model extends ITemplateModel {
	counterModel: CounterModel
	globalModel: GlobalModel
	formModel: FormModel
	filterModel: FilterModel
}

export const model: Model = {
	...TemplateModel,
	counterModel,
	globalModel,
	formModel,
	filterModel
}
