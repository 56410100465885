import React, { useState } from 'react'
import {
	Button,
	ButtonState,
	ButtonType,
	Dropdown,
	DropdownItemProps,
	Label,
	TemplateModal,
	TemplateModalAlignment,
	TemplateModalSize,
	Text,
	TextArea,
	TextType
} from '@mit/hui'
import { OpportunityController } from 'Api/controller'
import { BannerMessage } from '../BannerMessage'

interface Props {
	id: string
	show: boolean
	onComplete: () => void
	onClose: () => void
	applicationId: string
	opportunityId: string
}

export const ApplicationReviewForm: React.FC<Props> = ({ id, show, onComplete, onClose, applicationId, opportunityId }) => {
	const [action, setAction] = useState<DropdownItemProps>({ text: '', icon: '' })
	const [note, setNote] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [banner, setBanner] = useState<any>({ type: '', text: `` })

	// const handleSubmit = () => {
	// 	const controller = new OpportunityController()
	// 	setIsLoading(true)
	// 	controller
	// 		.rejectOpportunity(id, note)
	// 		.then(data => {
	// 			onComplete()
	// 			setIsLoading(false)
	// 		})
	// 		.catch(error => {
	// 			setBanner({ type: 'error', text: error })
	// 			setIsLoading(false)
	// 		})

	const getActionButtonEnabled = () => {
		return note.length > 0 && action.id
	}

	const handleReview = () => {
		const controller = new OpportunityController()
		setIsLoading(true)
		setBanner({ type: '', text: `` })
		controller
			.reviewApplication(opportunityId, applicationId, action.id as any, note)
			.then((data) => {
				console.log(data)
				onComplete()
				setIsLoading(false)
			})
			.catch((err) => {
				setBanner({ type: 'error', text: err })
				console.error(err)
				setIsLoading(false)
			})
	}

	return (
		<TemplateModal
			size={TemplateModalSize.Default}
			show={show}
			onClose={() => {
				setNote('')
				setAction({ text: '', icon: '' })
				onClose()
			}}
			imageUrl=''
			name='exampleModal'
			bodyAlignment={TemplateModalAlignment.Left}
			header={<Text content='Review Application' type={TextType.Heading4} icon='' />}
			body={
				<div className='d-flex flex-column'>
					<BannerMessage banner={banner} />
					<Label text='Action' />
					<Dropdown
						name='action'
						dropdownItems={[
							{ id: 'A', text: 'Approve Application', icon: '' },
							{ id: 'R', text: 'Reject Application', icon: '' },
							{ id: 'P', text: 'Request Change', icon: '' }
						]}
						text={'Please select an action'}
						onChange={(v: any) => setAction(v)}
					/>
					<br />
					<Label text='Note' />
					<span className='font-italic mb-1'>Note will be sent to the applicant</span>
					<TextArea
						rows={10}
						name='notes'
						value={note}
						onChange={(e: any) => setNote(e.target.value)}
						placeholderText='Please enter a note'
						required
					/>
				</div>
			}
			footer={
				<>
					<Button
						text='Submit Application Review'
						type={1}
						onClick={() => handleReview()}
						isBusy={isLoading}
						//state={!getActionButtonEnabled() ? ButtonState.Disabled : ButtonState.Enabled}
					/>
					{/* <Button
						text='Reject Application'
						type={ButtonType.Secondary | ButtonType.Small}
						onClick={() => handleReview('R')}
						isBusy={isLoading}
						state={!getActionButtonEnabled() ? ButtonState.Disabled : ButtonState.Enabled}
					/> */}
				</>
			}
		/>
	)
}
