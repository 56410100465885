import { BaseController } from './BaseController'

import { Profile } from 'Types'

export class ProfileController extends BaseController {
	async getProfile(): Promise<Profile> {
		let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPathDigitalId}/profile`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async getProfilePicture(id?: string): Promise<any> {
		let response = await this.useFetch('GET', `picture/${id}`, `${this.apiHost}/${this.apiPathDigitalId}/picture${id ? `/${id}` : ''}`)

		if (response.status === 200) {
			return response.blob()
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}
}
