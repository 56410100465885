import { Action, action } from 'easy-peasy'
import { getDefaultFilterObject } from 'Api/mock'
import { FilterObject } from 'Types'
import { debug } from 'console'

export interface State {
	show: boolean
	filters: FilterObject
}
export interface Actions {
	setShow: Action<this, boolean>
	setFilters: Action<this, FilterObject>
}

export interface Thunks { }

export interface FilterModel extends State, Actions, Thunks { }

export const filterModel: FilterModel = {
	show: false,
	filters: getDefaultFilterObject(),
	// Actions
	setShow: action((state, payload) => {
		state.show = payload
	}),
	setFilters: action((state, payload) => {
		state.filters = payload
	})
}
