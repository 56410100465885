import { Action, action } from 'easy-peasy'
import { Authorization, Lookups } from 'Types'

export interface State {
	auth: Authorization
	isLoading: boolean
	lookups: Lookups
	refreshOpportunities: boolean
}
export interface Actions {
	setAuth: Action<this, Authorization>
	setIsLoading: Action<this, boolean>
	setLookups: Action<this, Lookups>
	setRefreshOpportunities: Action<this>
}

export interface Thunks {}

export interface GlobalModel extends State, Actions, Thunks {}

export const globalModel: GlobalModel = {
	auth: {
		can_create: false,
		can_approve: false
	},
	isLoading: true,
	lookups: {
		// departments: [],
		// formTemplates: [],
		// funding_sources: [],
		// hours: [],
		// keywords: [],
		// learning_outcomes: [],
		// studentClassifications: [],
		// studentMajors: [],
		// studentYears: [],
		// terms: [],
		// themes: []
	},
	refreshOpportunities: false,

	// Actions
	setAuth: action((state, payload) => {
		state.auth = payload
	}),
	setIsLoading: action((state, payload) => {
		state.isLoading = payload
	}),
	setLookups: action((state, payload) => {
		state.lookups = payload
	}),
	setRefreshOpportunities: action((state) => {
		state.refreshOpportunities = !state.refreshOpportunities
	})
}
