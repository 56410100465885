import { Profile, SkeletonLoader } from '@mit/hui'
import React, { useEffect, useState } from 'react'
import { ProfileController } from 'Api/controller'

interface Props {
	id: string
}

const controller = new ProfileController()

export const UserPicture: React.FC<Props> = ({ id }) => {
	const [profilePicture, setProfilePicture] = useState<any>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		if (!!id) {
			setIsLoading(true)
			controller.getProfilePicture(id).then((data) => {
				let url = URL.createObjectURL(data)
				setProfilePicture(url)
				setIsLoading(false)
			})
		}
		//eslint-disable-next-line
	}, [id])

	return (
		<div className='user-picture'>
			{!!isLoading ? <SkeletonLoader /> : <Profile name={''} subtitle={''} imageUrl={profilePicture} submenu={[]} />}
		</div>
	)
}
