import React from 'react'
import { getStatusClass, getStatusText } from 'Common/Opportunity'
import { Opportunity } from 'Types'

interface Props {
	data?: Opportunity
	status?: { id: string | number; text: string }
}

export const OpportunityStatus: React.FC<Props> = ({ data, status }) => {
	return (
		<div className={`status ${getStatusClass(data?.status?.id ?? status?.id, !!status)}`}>
			<span>{status?.text ?? getStatusText(data?.status?.id, data?.status?.text)}</span>
		</div>
	)
}
