import { CategoryCardList } from '@mit/hui'
import React, { useEffect, useState } from 'react'

import { OpportunityController } from 'Api/controller'
import { getTileItems } from 'Common/Opportunity'
import { useStoreActions, useStoreState } from 'Store'
import { Opportunity } from 'Types/api'

// eslint-disable-next-line
interface Props { }

export const ArchivedOpportunities: React.FC<Props> = () => {
	const [opportunities, setOpportunities] = useState<Opportunity[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const { setOpportunityForm } = useStoreActions((action) => action.formModel)
	const { refreshOpportunities } = useStoreState((state) => state.globalModel)
	const { filters } = useStoreState((state) => state.filterModel)

	useEffect(() => {
		setIsLoading(true)

		const controller = new OpportunityController()
		controller
			.getOpportunities({}, false, true)
			.then((data) => {
				setOpportunities(data)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				console.log(error)
			})
	}, [refreshOpportunities, filters])

	const handleClick = (row: Opportunity) => {
		setOpportunityForm({
			type: 'UPDATE',
			show: true,
			id: row.id
		})
	}

	// if (isLoading) return <Loader isLoading />

	return (
		<div className='m-3'>
			<CategoryCardList isLoading={isLoading} items={getTileItems(opportunities, handleClick)} />
		</div>
	)
}
