import React from 'react'

import { NavBar, NavBarTypes, NavItemState } from '@mit/hui'

interface Props {
	can_approve: boolean
}

export const SideNavBar: React.FC<Props> = ({ can_approve }) => {
	let tabs: any = [
		{
			icon: 'browser',
			iconOnly: false,
			text: 'Opportunities',
			to: '/',
			state: window.location.pathname === '/' ? NavItemState.Active : NavItemState.None
		}
	]

	if (can_approve)
		tabs.push({
			icon: 'thumbs-up',
			iconOnly: false,
			text: 'Pending Approval',
			to: '/pending-approval',
			state: window.location.pathname === '/pending-approval' ? NavItemState.Active : NavItemState.None
		})

	return <NavBar type={NavBarTypes.IconText} tabs={tabs} />
}
