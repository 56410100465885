import React, { useEffect, useState } from 'react'

import { OpportunityController } from 'Api/controller'
import { useStoreActions, useStoreState } from 'Store'
import { Opportunity } from 'Types/api'

import { OpportunityNav } from '../nav'
import { NewOpportunity } from './NewOpportunity'

import { CategoryCardList } from '@mit/hui'
import { getTileItems } from 'Common/Opportunity'

// eslint-disable-next-line
interface Props {}

export const OpenOpportunities: React.FC<Props> = () => {
	const [opportunities, setOpportunities] = useState<Opportunity[]>([])
	const [selectedTabStatus, setSelectedTabStatus] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const { setOpportunityForm } = useStoreActions((action) => action.formModel)
	const { refreshOpportunities } = useStoreState((state) => state.globalModel)
	const { filters } = useStoreState((state) => state.filterModel)

	useEffect(() => {
		setIsLoading(true)

		const controller = new OpportunityController()
		controller
			.getOpportunities(filters)
			.then((data) => {
				setOpportunities(data)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				console.log(error)
			})
	}, [refreshOpportunities, filters])

	const handleClick = (row: Opportunity) => {
		setOpportunityForm({
			type: 'UPDATE',
			show: true,
			id: row.id
		})
	}

	const handleFilterChange = (status: string[]) => {
		setSelectedTabStatus(status)
	}

		const items = opportunities.filter((row: any) => (selectedTabStatus.length > 0 ? selectedTabStatus.includes(row.status?.id) : true))

	// if (isLoading) return <Loader isLoading />

	return (
		<div className='m-3'>
			<div className='mx-4'>
				<OpportunityNav items={opportunities} onClick={handleFilterChange} selectedStatus={selectedTabStatus} />
			</div>

			<div className={`${!isLoading ? 'category-cards' : ''} mx-4`}>
				{!isLoading && <NewOpportunity />}
				<CategoryCardList isLoading={isLoading} addWrapper={false} items={getTileItems(items, handleClick)} />
			</div>
		</div>
	)
}
