import { Alert, Text } from '@mit/hui'
import React from 'react'
import { getDateString } from 'Common'
import { IOpportunityNote } from 'Types'

interface OpportunityNoteProps {
	notes: IOpportunityNote[]
}

export const OpportunityNote: React.FC<OpportunityNoteProps> = ({ notes }) => {
	return (
		<>
			{notes.map((note) => (
				<Alert
					type='info'
					text={
						<>
							<Text content={`${note.note}`} />
							{note.status !== undefined && note.status?.id !== 'P' && (
								<>
									<span className=''>
										Status change: <b>{note.status?.text}</b>
									</span>
									<br />
								</>
							)}
							<span className=''>
								Created by:{' '}
								<b>
									{note.created_by} - {getDateString(note.created_on || '')}
								</b>
							</span>
						</>
					}
				/>
			))}
		</>
	)
}

OpportunityNote.defaultProps = {
	notes: []
}
