import { MITLoggedOut } from '@mit/hui'
import React from 'react'

interface LogoutProps {
	history: any
}

export const Logout: React.FC<LogoutProps> = ({ history }) => {
	const hasReauthenticated = localStorage.getItem('RefreshAuthentication') !== null
	if (hasReauthenticated) {
		localStorage.removeItem('RefreshAuthentication')
		history.push('/')
	}

	return <MITLoggedOut />
}
