import React from 'react'
import { useStoreActions } from 'Store'

export const NewOpportunity: React.FC = () => {
	const { setOpportunityForm } = useStoreActions((action) => action.formModel)

	const handleClick = () => {
		setOpportunityForm({
			type: 'CREATE',
			show: true
		})
	}

	return (
		<>
			<div className='tile-new-opportunity' onClick={handleClick}>
				<i className='fas fa-plus'></i>
				<h5>Add Opportunity</h5>
			</div>
		</>
	)
}
