import { BaseController } from './BaseController'

import { Application, Opportunity } from 'Types'

export class OpportunityController extends BaseController {
	async getOpportunities(filters: any, pending_approval?: boolean, expired?: boolean): Promise<Opportunity[]> {
		let query = ''
		let query_params: any = {
			pending_approval,
			expired
		}
		for (let key in filters) {
			let value = filters[key]
			query_params[key] = Array.isArray(value) ? value.map((item: any) => item.id).join(',') : value
			if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
				query_params[key] = value.id
			}
		}

		let keys = Object.keys(query_params).filter((key: any) => query_params[key])
		if (keys.length) {
			query = `?` + keys.map((key: string) => `${key}=${query_params[key]}`).join('&')
		}

		let response = await this.useFetch('GET', 'get/opportunities', `${this.apiHost}/${this.apiPath}/admin/opportunity${query}`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async getOpportunity(id: string): Promise<Opportunity> {
		let response = await this.useFetch('GET', 'get/opportunity', `${this.apiHost}/${this.apiPath}/admin/opportunity/${id}`)

		if (response.status === 200) {
			let data = await response.json()
			if (data.location) {
				data.location.selected = true
			}
			if (data.sponsor) {
				data.sponsor.selected = true
			}
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async createOpportuniy(body: Opportunity, auto_approve: boolean): Promise<Opportunity[]> {
		let response = await this.useFetch(
			'POST',
			'post/opportunities',
			`${this.apiHost}/${this.apiPath}/admin/opportunity${auto_approve ? '?auto_approve=true' : ''}`,
			body
		)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let data = await response.json()
			return Promise.reject(data.message)
		}
	}

	async updateOpportuniy(id: string, body: Opportunity, auto_approve: boolean): Promise<Opportunity[]> {
		let response = await this.useFetch(
			'PUT',
			'post/opportunities',
			`${this.apiHost}/${this.apiPath}/admin/opportunity/${id}${auto_approve ? '?auto_approve=true' : ''}`,
			body
		)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let data = await response.json()
			return Promise.reject(data.message)
		}
	}

	async approveOpportuniy(id: string): Promise<Opportunity[]> {
		let response = await this.useFetch('PUT', 'post/opportunities', `${this.apiHost}/${this.apiPath}/admin/opportunity/${id}/approve`, {
			action: 'A'
		})

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let data = await response.json()
			return Promise.reject(data.message)
		}
	}

	async rejectOpportunity(id: string, note: string): Promise<Opportunity[]> {
		let response = await this.useFetch('PUT', 'put/rejectOpportunity', `${this.apiHost}/${this.apiPath}/admin/opportunity/${id}/reject`, { note })

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let data = await response.json()
			return Promise.reject(data.message)
		}
	}

	async archiveOpportunity(id: string): Promise<Opportunity[]> {
		let response = await this.useFetch('PUT', 'put/archiveOpportunity', `${this.apiHost}/${this.apiPath}/admin/opportunity/${id}/archive`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let data = await response.json()
			return Promise.reject(data.message)
		}
	}

	async getApplications(id: string, showNew: boolean): Promise<Application[]> {
		let response = await this.useFetch(
			'GET',
			'get/opportunity',
			`${this.apiHost}/${this.apiPath}/opportunity/${id}/application${showNew ? '?all=true' : ''}`
		)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async getApplication(opportunity_id: string, application_id: string): Promise<Application> {
		let response = await this.useFetch(
			'GET',
			'get/opportunity',
			`${this.apiHost}/${this.apiPath}/opportunity/${opportunity_id}/application/${application_id}`
		)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}

	async reviewApplication(opportunityID: string, applicationId: string, action: 'A' | 'R', comment: string): Promise<any> {
		let response = await this.useFetch(
			'PUT',
			'put/review',
			`${this.apiHost}/${this.apiPath}/admin/opportunity/${opportunityID}/application/${applicationId}/approve`,
			{
				action,
				comment,
				applicationId
			}
		)
		if (response.status === 200) {
			const data = response.json()
			console.log({ data })
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}
}
