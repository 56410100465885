import React from 'react'
import { NavBar, NavBarTypes, NavItemState } from '@mit/hui'

interface Props {
	showNewApplications: boolean
	onClick: (showNewApplications: boolean) => void
}

export const ApplicationNav: React.FC<Props> = ({ showNewApplications, onClick }) => {
	return (
		<NavBar
			tabs={[
				{
					icon: '',
					iconOnly: false,
					text: 'Recent',
					state: showNewApplications ? NavItemState.Active : NavItemState.None,
					onClick: () => onClick(true)
				},
				{
					icon: '',
					iconOnly: false,
					text: 'All',
					state: !showNewApplications ? NavItemState.Active : NavItemState.None,
					onClick: () => onClick(false)
				}
			]}
			type={NavBarTypes.Text}
		/>
	)
}
