import React from 'react'

import { NavBar, NavBarTypes, NavItemState } from '@mit/hui'

interface Props {
	placeholder?: any
}

export const PendingNavBar: React.FC<Props> = () => {
	let tabs: any = [
		{
			icon: '',
			iconOnly: false,
			text: 'Pending Approval',
			state: window.location.pathname === '/pending-approval' ? NavItemState.Active : NavItemState.None
		}
	]

	return <NavBar type={NavBarTypes.Tabs} tabs={tabs} />
}
