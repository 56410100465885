
import { store } from 'Store'
import apiConfig from '../../Api/api-config'
import authProviderLocal from 'TemplateConstants/authorization/AuthProviderLocal';


export class BaseController {

  readonly apiHost
  readonly apiPathDigitalId
  readonly configKey : keyof typeof apiConfig;
  private readonly fetchController: Record<string, any>

  constructor () {

    let isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false;
    this.configKey = (isGlobalEnabled ? 'global': 'regional') as keyof typeof apiConfig;

    this.apiHost = apiConfig[this.configKey].apiHost ?? ''



    this.apiHost = apiConfig[this.configKey].apiHost ?? ''
    this.apiPathDigitalId =  apiConfig[this.configKey].paths.apiPathDigitalId ?? ''
    this.fetchController = {}
  }

  private async getHeaders (
    method: string,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<Headers> {
    const token: string = await authProviderLocal.getToken()

    const headers: { [key: string]: string } = {}

    if (!file) {
      headers['Content-Type'] = 'application/json'
      headers.Authorization = `Bearer ${token}`
    }

    if (impersonateKerb !== '') {
      headers['X-Impersonated-User'] = impersonateKerb
    }

    return new Headers({ ...headers, ...overrideHeaders })
  }

  async useFetch (
    method: string,
    fetchKey: string,
    url: string,
    body: any = null,
    overrideHeaders: Record<string, string> = {},
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<any> {
    if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method: method,
      headers: await this.getHeaders(method, overrideHeaders, file, impersonateKerb),
      signal
    }
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    this.fetchController[fetchKey] = null

    // if (response.status === 503) {
    //   store.getActions().RegionModel.checkRegionUpdate({ hasHttpError: true })
    // }

    return response
  }
}
