import { BaseController } from './BaseController'

import { Lookups } from 'Types'

export class LookupsController extends BaseController {
	async getLookups(): Promise<Lookups> {
		let response = await this.useFetch('GET', 'profile', `${this.apiHost}/${this.apiPath}/lookups`)

		if (response.status === 200) {
			let data = response.json()
			return data
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}
}
