import React from 'react'

import { NavBar, NavBarTypes, NavItemProps, NavItemState } from '@mit/hui'
import { OpportunityFormType } from 'Types'

export type OpportunityFormSelectedTab = 'Details' | 'Submissions'

interface Props {
	type: OpportunityFormType
	selectedTab: OpportunityFormSelectedTab
	onChange: (tab: OpportunityFormSelectedTab) => void
}

export const OpportunityHeaderNav: React.FC<Props> = ({ type, selectedTab, onChange }) => {
	const navTabs: NavItemProps[] = [
		{
			icon: '',
			iconOnly: false,
			text: 'Details',
			state: selectedTab === 'Details' ? NavItemState.Active : NavItemState.None,
			onClick: () => onChange('Details')
		}
	]

	if (type !== 'CREATE' && type !== 'APPROVE') {
		navTabs.push({
			icon: '',
			iconOnly: false,
			text: 'Submissions',
			state: selectedTab === 'Submissions' ? NavItemState.Active : NavItemState.None,
			onClick: () => onChange('Submissions')
		})
	}

	return (
		<div>
			<NavBar type={NavBarTypes.Tabs} tabs={navTabs} />
		</div>
	)
}
