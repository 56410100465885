import { Action, action } from 'easy-peasy'
import { OpportunityFormType } from 'Types'

interface FormObject {
	type: OpportunityFormType
	show: boolean
	id?: string
}

export interface State {
	opportunityForm: FormObject
}
export interface Actions {
	setOpportunityForm: Action<this, FormObject>
}

export interface Thunks {}

export interface FormModel extends State, Actions, Thunks {}

export const formModel: FormModel = {
	opportunityForm: {
		type: 'CREATE',
		show: false
	},
	// Actions
	setOpportunityForm: action((state, payload) => {
		state.opportunityForm = payload
	})
}
