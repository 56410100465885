import React, { useEffect, useState } from 'react'

import { useStoreActions, useStoreState } from 'Store'
import {
	Drawer,
	DrawerLayout,
	DrawerSize,
	DrawerType,
	Text,
	TextType
	// Button
} from '@mit/hui'
import { OpportunityHeaderNav, OpportunityFormSelectedTab, OpportunityForm } from './'
import { OpportunityStatus } from '../../opportunity/OpportunityStatus'
import { LookupsController, OpportunityController } from 'Api/controller'
import { Opportunity } from 'Types'
import { Loader } from 'Components/Loader'
import { NewOpportunityDefault } from 'Api/mock'
import { Banner } from 'Types/banner'
import { OpportunitySubmissions } from '../../opportunity'
import cloneDeep from 'clone-deep'
import { OpportunityArchive } from './OpportunityArchive'

export const OpportunityFormDrawer: React.FC = () => {
	const { opportunityForm } = useStoreState((state) => state.formModel)
	const { lookups, auth } = useStoreState((state) => state.globalModel)
	const { setRefreshOpportunities, setLookups } = useStoreActions((action) => action.globalModel)
	const { setOpportunityForm } = useStoreActions((action) => action.formModel)
	const [selectedTab, setSelectedTab] = useState<OpportunityFormSelectedTab>('Details')
	const [formData, setFormData] = useState<Opportunity>({} as any)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [banner, setBanner] = useState<Banner>({ type: 'info', text: '' })

	const handleClose = () => {
		setSelectedTab('Details')
		setBanner({ type: 'info', text: '' })
		setOpportunityForm({
			type: 'CREATE',
			show: false
		})
	}

	useEffect(() => {
		if (opportunityForm.id) {
			getOpportunity(opportunityForm.id)
		} else {
			let data: any = NewOpportunityDefault
			setFormData(cloneDeep(data))
		}
		//eslint-disable-next-line
	}, [opportunityForm.show])

	const getOpportunity = (id: string) => {
		const controller = new OpportunityController()
		setIsLoading(true)

		controller.getOpportunity(id).then((data) => {
			setFormData(data)
			setIsLoading(false)
			if (opportunityForm.type !== 'APPROVE') setOpportunityForm({ id: opportunityForm.id, type: 'UPDATE', show: true })
		})

		const lookupController = new LookupsController()
		lookupController.getLookups().then((data) => {
			setLookups(data)
		})
	}

	const handleArchiveSuccess = () => {
		setRefreshOpportunities()
		setOpportunityForm({ id: '', type: 'UPDATE', show: false })
	}

	return (
		<>
			<Drawer
				show={opportunityForm.show}
				onClose={handleClose}
				type={DrawerType.Right}
				size={DrawerSize.Medium}
				layout={DrawerLayout.Clean}
				themedColor='ruby'
				noExternalClose={true}
				header={
					<div className='d-flex flex-column'>
						<Text type={TextType.Heading3} content='Opportunity Details' />
						{!!formData.id && (
							<>
								<br />
								<div className='d-flex justify-content-between'>
									{!!formData.status && <OpportunityStatus data={formData} />}

									<OpportunityArchive data={formData} onSuccess={handleArchiveSuccess} />
								</div>
							</>
						)}
					</div>
				}
				headerNavBar={
					<OpportunityHeaderNav
						type={opportunityForm.type}
						selectedTab={selectedTab}
						onChange={(tab: OpportunityFormSelectedTab) => setSelectedTab(tab)}
					/>

					// <> </>
				}
				footer={[]}
				contents={
					<>
						{isLoading ? (
							<Loader isLoading />
						) : selectedTab === 'Details' ? (
							<OpportunityForm
								type={opportunityForm.type}
								data={formData}
								lookups={lookups}
								refreshOpportunities={setRefreshOpportunities}
								approver_themes={auth.approver_themes ?? []}
								onUpdate={getOpportunity}
								banner={banner}
								setBanner={setBanner}
							/>
						) : (
							<OpportunitySubmissions data={formData} lookups={lookups} />
						)}
					</>
				}
			/>
		</>
	)
}
