import React, { useState } from 'react'
import { AutoComplete } from '@mit/hui'
import { PeopleController } from 'Api/controller'

interface PersonItem {
	id: string | number
	text: string
	icon?: string
}

interface Props {
	name: string
	onChange?: (v: PersonItem) => void
	value?: PersonItem
	required?: boolean
	errors?: any
}

const controller = new PeopleController()

export const PeopleSearch: React.FC<Props> = ({ name, required, value, errors, onChange }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [items, setItems] = useState<any>([])

	const onSearch = (q: string) => {
		setIsLoading(true)
		controller
			.getPeople(q)
			.then((data) => {
				setIsLoading(false)
				setItems(data)
			})
			.catch((error) => {
				console.error(error)
				// setIsLoading(false)
			})
	}

	return (
		<>
			<AutoComplete
				name={name}
				items={items || []}
				searchOptions={{ name: '', placeholderText: 'Search for a person by name or MIT Kerberos ID/email' }}
				isBusy={isLoading}
				value={value}
				required={required}
				onSearch={onSearch}
				limit={20}
				errors={errors}
				onChange={(v: PersonItem) => onChange && onChange(v)}
				clearOnBlur
			/>
		</>
	)
}
