import { appInitialized } from '@mit/aws-react'
import { BaseController, LookupsController } from 'Api/controller'
// import { AppInitializer } from 'Components/app/AppInitializer'
import { useState } from 'react'
import {  useStoreActions } from 'Store/hooks'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)
  const { setLookups, setAuth } = useStoreActions((actions) => actions.globalModel)
  const { setHasAccess, setIsLoading } = useStoreActions((action) => action.LoaderModel)

  appInitialized({
    isLoading: false,
    hasAccess: true,
    hasError: false
  })


const initializeApp = async (): Promise<void> => {
  const baseController = new BaseController()
  const lookupsController = new LookupsController()

  let resp: any = await Promise.allSettled([baseController.getAuthorization(), lookupsController.getLookups()])

  let auth = {
    can_approve: false,
    can_create: false
  }

  if (resp[0].status === 'fulfilled') {
    auth = resp[0].value
    setAuth(auth)
  }

  if (resp[1].status === 'fulfilled') {
    setLookups(resp[1].value)
  }

  setHasAccess(auth.can_approve || auth.can_create)
  setIsLoading(false)

  appInitialized({
    isLoading: false,
    hasAccess: auth.can_approve || auth.can_create,
    hasError: false
  })

  setInitializeApi(true)

}
return { initializeApp }
}