import { Drawer, DrawerLayout, DrawerSize, DrawerType, Text, TextType, SkeletonLoader, Button } from '@mit/hui'
import React, { useEffect, useState } from 'react'
import { OpportunityController } from 'Api/controller'
import { getDateString } from 'Common'
import { Application, Lookups } from 'Types'
import { UserPicture } from '../user/UserPicture'
import { ApplicationDocuments } from './ApplicationDocuments'
import { ApplicationReviewForm } from 'Components/form/opportunity/ApplicationReviewForm'
import { ApplicationNote } from './ApplicationNote'
import { OpportunityStatus } from './OpportunityStatus'
import { UropAlert } from 'Components/form/opportunity/UropAlert'

interface Props {
	opportunity_id: string
	application_id: string
	show: boolean
	lookups: Lookups
	onClose: () => void
	refreshApplications: () => void
}

const controller = new OpportunityController()

export const ApplicationDrawer: React.FC<Props> = ({ opportunity_id, application_id, show, lookups, onClose, refreshApplications }) => {
	const [data, setData] = useState<Application>({})
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [showReviewModal, setShowReviewModal] = useState<boolean>(false)

	useEffect(() => {
		if (!!application_id && !!show) {
			getApplication()
		}
		// eslint-disable-next-line
	}, [show, application_id])

	const getApplication = () => {
		setIsLoading(true)
		controller
			.getApplication(opportunity_id, application_id)
			.then((data) => {
				setData(data)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
			})
	}

	return (
		<Drawer
			show={show}
			onClose={onClose}
			type={DrawerType.Right}
			size={DrawerSize.Small}
			layout={DrawerLayout.Clean}
			themedColor='ruby'
			footer={[]}
			noExternalClose={true}
			header={
				<>
					<div className='d-flex flex-column'>
						<Text type={TextType.Heading3} content={`Application`} />
						<br />
						{!!data.status && <OpportunityStatus status={data.status} />}
					</div>
				</>
			}
			contents={
				isLoading ? (
					<SkeletonLoader />
				) : (
					<div className='mt-5'>
						<div className='d-flex'>
							<UserPicture id={data.mit_id || ''} />
							<div className='pl-3'>
								<Text type={TextType.Heading4} content={`${data.student?.first_name} ${data.student?.last_name} (${data.mit_id})`} />
								<Text type={TextType.Body} content={data.email_address || ''} />
								<Text type={TextType.Body} content={data.student?.degree_description || ''} />
								<Text type={TextType.Body} content={`Applied on ${getDateString(data.created_on || '')}`} />
							</div>
						</div>
						<br />
						{data?.primary_theme?.id === 1 && (
							<UropAlert
								urop_deadlines={{
									sponsor_funded_deadline: lookups?.urop_deadlines?.sponsor_funded_deadline,
									urop_funded_deadline: lookups?.urop_deadlines?.urop_funded_deadline,
									term: lookups?.urop_deadlines?.term
								}}
							/>
						)}

						<Text type={TextType.Heading2} content={`Application Answers`} />

						{data.form_json?.map((row: any) => (
							<div className='my-3'>
								<Text content={row.text} padded={false} />
								<Text type={TextType.Heading5} content={!!row.value ? row.value : 'N/A'} padded={false} />
							</div>
						))}
						{!!data.documents?.length && (
							<>
								<br />
								<Text type={TextType.Heading2} content={`Documents`} />
								<br />
								<ApplicationDocuments documents={data.documents || []} />
							</>
						)}
						<ApplicationReviewForm
							id={''}
							show={showReviewModal}
							onComplete={() => {
								setShowReviewModal(false)
								getApplication()
								refreshApplications()
							}}
							onClose={() => setShowReviewModal(false)}
							applicationId={application_id}
							opportunityId={opportunity_id}
						/>
						<br />
						<div>
							<Text type={TextType.Heading5} content={'Review Notes'} />
							<ApplicationNote notes={data.notes as any} />
						</div>
						<Button
							type={1}
							text='Review Application'
							onClick={() => {
								setShowReviewModal(true)
							}}
						/>
					</div>
				)
			}
		/>
	)
}
