import React, { useState } from 'react'

import { Button, TemplateModal, TemplateModalSize, TemplateModalAlignment, Text, TextType, ButtonType } from '@mit/hui'
import { FormField } from 'Components/form'
import { useForm } from 'react-hook-form'

interface Props {
	data: any
}

export const TemplatePreview: React.FC<Props> = ({ data }) => {
	const [show, setShow] = useState<boolean>(false)

	console.log(data)

	if (!data) {
		data = { text: '', form_json: { DATA: [] } }
	}

	let defaultValues: any = {}

	data?.form_json?.DATA?.map((row: any, index: number) => {
		defaultValues[`${index}`] = ''
		return row
	})

	const methods: ReturnType<typeof useForm> = useForm<any>({
		defaultValues: defaultValues
	})

	return (
		<>
			<div className='mt-2'>
				<Button text='Preview Template' onClick={() => setShow(true)} type={ButtonType.TextNaked} padded={false} />
			</div>
			<TemplateModal
				onClose={() => setShow(false)}
				show={show}
				theme='ruby'
				size={TemplateModalSize.Large}
				imageUrl=''
				name='templatePreviewModal'
				bodyAlignment={TemplateModalAlignment.Right}
				header={<Text content={`${data.text} Template Form Preview`} type={TextType.Heading4} icon='' />}
				body={
					<>
						{!!data &&
							data?.items?.map((row: any, index: number) => (
								<FormField
									editor={row.component_type}
									label={row.text}
									name={`${index}`}
									methods={methods}
									properties={{ placeholder: ' ' }}
								/>
							))}
					</>
				}
				footer={<></>}
			/>
		</>
	)
}
