import React from 'react'

import { FileIcon } from '@mit/hui'

interface Props {
	documents: any[]
}

export const ApplicationDocuments: React.FC<Props> = ({ documents }) => {
	const downloadFile = (document: any) => {
		window.open(document.url, '_blank')
	}

	return (
		<div className='file-container d-flex'>
			{documents.map((row: any) => (
				<div className='file-icon-container' onClick={() => downloadFile(row)}>
					<FileIcon text={row?.filename} type={row.content_type} />
				</div>
			))}
		</div>
	)
}
