import { BaseController } from './BaseController'

import { PeopleSearchResponse } from 'Types'

export class PeopleController extends BaseController {
	// mapPeopleResponse(data: any): PeopleSearchResponse[] {
	// 	let resp = data.hits.hit.map((row: any) => {
	// 		return {
	// 			id: row.fields.mit_id,
	// 			text: `${row.fields.first_name} ${row.fields.last_name} (${row.fields.krb_name})`,
	// 			krb_name: row.fields.krb_name
	// 		}
	// 	})
	// 	return resp
	// }

	mapPeopleResponse(data: any): PeopleSearchResponse[] {
		let resp = data.map((row: any) => {
			return {
				id: row.mit_id,
				text: `${row.first_name} ${row.last_name} (${row.krb_name})`,
				krb_name: row.krb_name
			}
		})
		return resp
	}

	async getPeople(q: string): Promise<PeopleSearchResponse[]> {
		let response = await this.useFetch('GET', 'get/people', `${this.apiHost}/${this.apiPathDigitalId}/search?q=${q}`)

		if (response.status === 200) {
			let data = await response.json()
			return this.mapPeopleResponse(data)
		} else {
			let text = await response.text()
			return Promise.reject(text)
		}
	}
}
