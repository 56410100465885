const appConfig = {
  "name": "elo-admin-v2",
  "version": "0.1.0",
  "stage": "prod",
  "deployThisBuild": false,
  "okta": {
    "issuer": "https://mitprod.okta.com/oauth2/aus6sh93rjqnQuszg697",
    "clientId": "jifnaa6kt03hoec59967fqo93",
    "redirectUris": [
      "https://elx-admin-mr.atlas-apps.mit.edu"
    ],
    "postLogoutRedirectUris": [
      "https://elx-admin-mr.atlas-apps.mit.edu/logout"
    ],
    "scopes": [
      "digital-id/search",
      "digital-id/user",
      "elo/admin",
      "elo/user",
      "openid",
      "profile",
      "user/all"
    ]
  },
  "amplify": {
    "userPoolId": "us-east-1_bCu6BU8d2",
    "userPoolWebClientId": "jifnaa6kt03hoec59967fqo93",
    "oauth": {
      "domain": "atlas-auth.mit.edu",
      "scope": [
        "digital-id/search",
        "digital-id/user",
        "elo/admin",
        "elo/user",
        "openid",
        "profile"
      ],
      "redirectUris": [
        "https://elx-admin-mr.atlas-apps.mit.edu"
      ],
      "postLogoutRedirectUris": [
        "https://elx-admin-mr.atlas-apps.mit.edu/logout"
      ],
      "responseType": "token",
      "options": {
        "AdvancedSecurityDataCollectionFlag": false
      }
    }
  }
};
export default appConfig;