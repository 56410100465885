import React, { useState } from 'react'
import { Button, ButtonState, ButtonType, Label, TemplateModal, TemplateModalAlignment, TemplateModalSize, Text, TextArea, TextType } from '@mit/hui'
import { OpportunityController } from 'Api/controller'
import { BannerMessage } from '../BannerMessage'

interface Props {
	id: string
	show: boolean
	onComplete: () => void
	onClose: () => void
}

export const OpportunityRejectionForm: React.FC<Props> = ({ id, show, onComplete, onClose }) => {
	const [note, setNote] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [banner, setBanner] = useState<any>({ type: '', text: `` })

	const handleSubmit = () => {
		const controller = new OpportunityController()
		setIsLoading(true)
		controller
			.rejectOpportunity(id, note)
			.then((data) => {
				onComplete()
				setIsLoading(false)
			})
			.catch((error) => {
				setBanner({ type: 'error', text: error })
				setIsLoading(false)
			})
	}

	return (
		<TemplateModal
			size={TemplateModalSize.Default}
			show={show}
			onClose={() => {
				setNote('')
				onClose()
			}}
			imageUrl=''
			name='exampleModal'
			bodyAlignment={TemplateModalAlignment.Right}
			header={<Text content='Request Change' type={TextType.Heading4} icon='' />}
			body={
				<div className='d-flex flex-column'>
					<BannerMessage banner={banner} />
					<Label text='Change Note' />
					<span className='font-italic mb-3'>Note will be sent to creator/updater</span>
					<TextArea
						rows={10}
						name='notes'
						value={note}
						onChange={(e: any) => setNote(e.target.value)}
						placeholderText='Please enter a note'
						required
					/>
				</div>
			}
			footer={
				<Button
					text='Request Change & Send Note'
					type={ButtonType.Primary | ButtonType.Small}
					onClick={handleSubmit}
					isBusy={isLoading}
					state={note.length === 0 ? ButtonState.Disabled : ButtonState.Enabled}
				/>
			}
		/>
	)
}
