import { SkeletonLoader, Text, TextType } from '@mit/hui'
import React, { useEffect, useState } from 'react'
import { OpportunityController } from 'Api/controller'
import { getDateString, getStatusTextClass } from 'Common'

import { Application, Lookups, Opportunity } from 'Types'
import { ApplicationNav } from '../nav/ApplicationNav'
import { ApplicationDrawer } from './ApplicationDrawer'
import { UserPicture } from '../user/UserPicture'

interface Props {
	data: Opportunity
	lookups: Lookups
}

const controller = new OpportunityController()

export const OpportunitySubmissions: React.FC<Props> = ({ data, lookups }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [show, setShow] = useState(false)
	const [items, setItems] = useState<Application[]>([])
	const [selectedApplication, setSelectedApplication] = useState<Application>({})
	const [showNewApplications, setShowNewApplications] = useState<boolean>(true)

	useEffect(() => {
		getApplications()
		//eslint-disable-next-line
	}, [data.id, showNewApplications])

	const onClickApplication = (application: Application) => {
		setSelectedApplication({ ...application })
		setShow(true)
	}

	const getApplications = () => {
		setIsLoading(true)
		controller
			.getApplications(data.id, !showNewApplications)
			.then((data) => {
				setItems(data)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
			})
	}

	// if (isLoading) {
	// 	return <SkeletonLoader />
	// }

	return (
		<>
			<ApplicationDrawer
				opportunity_id={data.id}
				application_id={selectedApplication.id || ''}
				show={show}
				onClose={() => setShow(false)}
				refreshApplications={getApplications}
				lookups={lookups}
			/>
			<div className='mt-5 '>
				<ApplicationNav showNewApplications={showNewApplications} onClick={(value: boolean) => setShowNewApplications(value)} />
				<br />
				{isLoading ? (
					<SkeletonLoader />
				) : !!items.length ? (
					items.map((row) => (
						<div
							className='py-3 d-flex justify-content-between border-top application-list-row'
							key={row.id}
							onClick={() => onClickApplication(row)}
						>
							<div className='d-flex'>
								<UserPicture id={row.mit_id || ''} />
								<div className='ml-3'>
									<Text content={`${row.student?.first_name} ${row.student?.last_name} (${row.mit_id})`} type={TextType.Heading5} />
									<Text content={`Applied on ${getDateString(row.created_on || '')}`} type={TextType.Body} />
									{/* <Text content={`${row.student?.ult_degree_desc}`} type={TextType.Body} /> */}
								</div>
							</div>
							<h6 className={getStatusTextClass((row.status?.id as string) ?? 'P')}>{row.status?.text}</h6>
							{/* <Text content={`Applied on ${getDateString(row.created_on || '')}`} type={TextType.Body} /> */}
						</div>
					))
				) : (
					<div className='d-flex justify-content-center'>
						<Text type={TextType.Heading3} content='No Submissions' />
					</div>
				)}
			</div>
		</>
	)
}
