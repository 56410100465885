import React from 'react'

import { NavBar, NavBarTypes, NavItemState } from '@mit/hui'

interface Props {
	placeholder?: any
}

export const MainNavBar: React.FC<Props> = () => {
	let tabs: any = [
		{
			icon: '',
			iconOnly: false,
			text: 'Open Opportunities',
			to: '/',
			state: window.location.pathname === '/' ? NavItemState.Active : NavItemState.None
		},
		{
			icon: '',
			iconOnly: false,
			text: 'Archived Opportunities',
			to: '/archived-opportunities',
			state: window.location.pathname === '/archived-opportunities' ? NavItemState.Active : NavItemState.None
		}
	]

	return <NavBar type={NavBarTypes.Tabs} tabs={tabs} />
}
