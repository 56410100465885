import React, { useEffect } from 'react'
import { Button, ButtonType, Drawer, DrawerLayout, DrawerSize, DrawerType, Text, TextType } from '@mit/hui'
import { useStoreActions, useStoreState } from 'Store'
import { FormField } from 'Components/form'
import { useForm } from 'react-hook-form'
import { getDefaultFilterObject } from 'Api/mock'
import cloneDeep from 'clone-deep'
import { lookup } from 'dns'

export const OpportunityFilterForm: React.FC = () => {
	const { show, filters } = useStoreState((state) => state.filterModel)
	const { lookups } = useStoreState((state) => state.globalModel)
	const { setShow, setFilters } = useStoreActions((action) => action.filterModel)

	const methods: ReturnType<typeof useForm> = useForm<any>({
		defaultValues: filters,
		reValidateMode: 'onSubmit'
	})
	const { watch, reset } = methods
	let formData: any = watch()

	console.log('filters', filters)
	console.log('formData', formData)

	useEffect(() => {
		reset(filters)
	}, [filters, reset])
	console.log('filters', methods.formState)
	return (
		<Drawer
			show={show}
			onClose={() => setShow(false)}
			type={DrawerType.Right}
			size={DrawerSize.Small}
			layout={DrawerLayout.Clean}
			themedColor='ruby'
			footer={[]}
			noExternalClose={false}
			header={
				<>
					{' '}
					<Text type={TextType.Heading3} content={`Opportunity Filters`} />{' '}
				</>
			}
			contents={
				<div className='mt-3 px-1'>
					<form id='opportunity-form'>
						<FormField
							readonly={false}
							name='departments'
							editor='autocomplete'
							methods={methods}
							label='Department/Program'
							properties={{
								items: lookups.departments || [],
								placeholder: 'Select a department/program',
								showItemsOnFocus: true,
								type: 'tags',
								limit: 200
							}}
						/>
						<FormField
							readonly={false}
							name='themes'
							editor='autocomplete'
							methods={methods}
							label='Theme / Track / Program'
							properties={{
								items: lookups.themes || [],
								placeholder: 'Select all theme/tracks/program that apply',
								type: 'tags',
								showItemsOnFocus: true
							}}
						/>
						<FormField
							readonly={false}
							name='terms'
							editor='autocomplete'
							methods={methods}
							label='Term'
							properties={{
								items: lookups.terms || [],
								placeholder: 'Select all terms that apply',
								type: 'tags',
								showItemsOnFocus: true
							}}
						/>
						<FormField
							readonly={false}
							name='sponsors'
							editor='person-search'
							methods={methods}
							label='PI / Faculty Sponsor / Program Director'
							properties={{
								placeholder: 'Search for a person'
							}}
						/>
						<FormField
							readonly={false}
							name='has_applications'
							editor='toggle'
							methods={methods}
							label='Has Application'
							properties={{}}
						/>
					</form>
					<div className='d-flex'>
						<Button type={ButtonType.Primary} text='Apply Filter'
							onClick={() => {
								setFilters(cloneDeep(formData))
								setShow(false)
							}} />
						<Button
							type={ButtonType.Ghost}
							text='Clear Filters'
							onClick={() => {
								setFilters(getDefaultFilterObject())
								reset(getDefaultFilterObject())
								setShow(false)
							}}
						/>
					</div>
				</div>
			}
		/>
	)
}
